declare global {
  interface Window {
    ENV: {
      stripeApiKey: string;
      productsAvailable: boolean;
      trialFontsAvailable: boolean;
    };
  }
}

import 'styles/application.scss';

import React from 'react';
import ReactDOM from 'react-dom';

import Application from 'components/Application';

const container = document.getElementById('application');

if (container != null) {
  ReactDOM.render(<Application />, container);
}
