export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
}

export interface Query {
  __typename: 'Query';
  article: Maybe<Article>;
  articlePages: ArticlePages;
  articleTags: Array<ArticleTag>;
  countries: Array<Country>;
  currentOrder: Maybe<Order>;
  currentOrderTotals: Maybe<OrderTotals>;
  currentUser: Maybe<User>;
  events: Array<Event>;
  faces: Array<FontFace>;
  families: Array<FontFamily>;
  family: Maybe<FontFamily>;
  faqGroups: Array<FaqGroup>;
  fontLicense: Maybe<FontLicense>;
  homePageFeatures: Array<HomePageFeature>;
  invoice: Maybe<Invoice>;
  latestArticle: Maybe<Article>;
  license: Maybe<FontLicense>;
  licenseOptions: Array<FontLicenseOption>;
  order: Maybe<Order>;
  page: Maybe<Page>;
  products: Array<Product>;
  projects: Array<Project>;
  usageExample: Maybe<FontUsageExample>;
  usageExamples: Array<FontUsageExample>;
}


export interface QueryArticleArgs {
  slug: Scalars['String']['input'];
}


export interface QueryArticlePagesArgs {
  page: Scalars['Int']['input'];
  tag?: InputMaybe<Scalars['String']['input']>;
}


export interface QueryCurrentOrderTotalsArgs {
  billingCountryCode: Scalars['String']['input'];
  billingPostalCode: Scalars['String']['input'];
  shippingCountryCode?: InputMaybe<Scalars['String']['input']>;
  shippingPostalCode?: InputMaybe<Scalars['String']['input']>;
}


export interface QueryFacesArgs {
  ids: Array<Scalars['Int']['input']>;
}


export interface QueryFamilyArgs {
  slug: Scalars['String']['input'];
}


export interface QueryFontLicenseArgs {
  id: Scalars['Int']['input'];
}


export interface QueryInvoiceArgs {
  slug: Scalars['String']['input'];
}


export interface QueryLicenseArgs {
  id: Scalars['Int']['input'];
}


export interface QueryOrderArgs {
  number: Scalars['String']['input'];
}


export interface QueryPageArgs {
  slug: Scalars['String']['input'];
}


export interface QueryProjectsArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
}


export interface QueryUsageExampleArgs {
  slug: Scalars['String']['input'];
}


export interface QueryUsageExamplesArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
}

export interface Article {
  __typename: 'Article';
  author: Maybe<Scalars['String']['output']>;
  bannerImageUrl: Maybe<Scalars['String']['output']>;
  content: Scalars['String']['output'];
  excerpt: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  longForm: Scalars['Boolean']['output'];
  publishedAt: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  tags: Array<Scalars['String']['output']>;
  title: Scalars['String']['output'];
}

export interface ArticlePages {
  __typename: 'ArticlePages';
  articles: Array<Article>;
  count: Scalars['Int']['output'];
}

export interface ArticleTag {
  __typename: 'ArticleTag';
  articlesCount: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
}

export interface Country {
  __typename: 'Country';
  alpha2: Scalars['String']['output'];
  name: Scalars['String']['output'];
}

export interface Order {
  __typename: 'Order';
  adjustmentsTotal: Scalars['Float']['output'];
  billingAddress: Maybe<Address>;
  canceledAt: Maybe<Scalars['String']['output']>;
  completedAt: Maybe<Scalars['String']['output']>;
  fontLicenses: Array<FontLicense>;
  id: Scalars['Int']['output'];
  licenseeAddress: Maybe<Address>;
  lineItems: Array<LineItem>;
  mobileLicenseDetails: Maybe<Scalars['String']['output']>;
  number: Scalars['String']['output'];
  payment: Maybe<Payment>;
  shippingAddress: Maybe<Address>;
  shippingTotal: Scalars['Float']['output'];
  state: Scalars['String']['output'];
  subtotal: Scalars['Float']['output'];
  taxTotal: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
  upgradeDescription: Maybe<Scalars['String']['output']>;
  webLicenseDetails: Maybe<Scalars['String']['output']>;
}

export interface Address {
  __typename: 'Address';
  address1: Scalars['String']['output'];
  address2: Maybe<Scalars['String']['output']>;
  country: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  locality: Scalars['String']['output'];
  name: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
  region: Scalars['String']['output'];
}

export interface FontLicense {
  __typename: 'FontLicense';
  adobeFontsToken: Maybe<Scalars['String']['output']>;
  archive: Maybe<FontLicenseArchive>;
  faces: Array<FontFace>;
  family: FontFamily;
  id: Scalars['Int']['output'];
  option: FontLicenseOption;
  total: Scalars['Float']['output'];
  version: Maybe<Scalars['Int']['output']>;
}

export interface FontLicenseArchive {
  __typename: 'FontLicenseArchive';
  expiringUrl: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  status: Scalars['String']['output'];
}

export interface FontFace {
  __typename: 'FontFace';
  cssClassName: Scalars['String']['output'];
  cssDeclaration: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  displayOrder: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  previewUrl: Maybe<Scalars['String']['output']>;
  retinaPreviewUrl: Maybe<Scalars['String']['output']>;
  size: Scalars['String']['output'];
  style: Scalars['String']['output'];
  variant: Scalars['String']['output'];
  weight: Scalars['String']['output'];
  width: Scalars['String']['output'];
}

export interface FontFamily {
  __typename: 'FontFamily';
  article: Maybe<Article>;
  articleImageUrl: Maybe<Scalars['String']['output']>;
  availableAt: Maybe<Scalars['String']['output']>;
  backgroundColor: Scalars['String']['output'];
  color: Scalars['String']['output'];
  completePackageDescription: Maybe<Scalars['String']['output']>;
  completePrice: Scalars['Float']['output'];
  description: Maybe<Scalars['String']['output']>;
  designCredit: Maybe<Scalars['String']['output']>;
  discountedFontFacePrice: Scalars['Float']['output'];
  faces: Array<FontFace>;
  features: Array<FontFeature>;
  featuresDeclaration: Maybe<Scalars['String']['output']>;
  fontFacePrice: Scalars['Float']['output'];
  foregroundColor: Scalars['String']['output'];
  headingFace: Maybe<FontFace>;
  headingLetterSpacing: Scalars['Int']['output'];
  headingTextTransform: TextTransform;
  id: Scalars['Int']['output'];
  logoBackgroundColor: Maybe<Scalars['String']['output']>;
  maxTesterFontSize: Scalars['Float']['output'];
  minTesterFontSize: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  onHomePage: Scalars['Boolean']['output'];
  packages: Array<FontPackage>;
  position: Maybe<Scalars['Int']['output']>;
  previewFontFace: Maybe<FontFace>;
  previews: Array<FontPreview>;
  products: Array<Product>;
  slug: Scalars['String']['output'];
  specimenDocumentUrl: Maybe<Scalars['String']['output']>;
  specimenPreviewUrl: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  supportedLanguages: Maybe<Array<Scalars['String']['output']>>;
  trialArchives: Array<FontTrialArchive>;
  typeTesters: Array<FontTypeTester>;
}

export interface FontFeature {
  __typename: 'FontFeature';
  caption: Maybe<Scalars['String']['output']>;
  characterCodes: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  position: Scalars['Int']['output'];
}

export enum TextTransform {
  Capitalize = 'CAPITALIZE',
  Uppercase = 'UPPERCASE'
}

export interface FontPackage {
  __typename: 'FontPackage';
  description: Maybe<Scalars['String']['output']>;
  discountedPrice: Maybe<Scalars['Float']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  size: Maybe<Scalars['String']['output']>;
  variant: Scalars['String']['output'];
  width: Maybe<Scalars['String']['output']>;
}

export interface FontPreview {
  __typename: 'FontPreview';
  face: FontFace;
  fontSizeScale: Scalars['Float']['output'];
  hasOverrideFont: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  letterSpacing: Scalars['Float']['output'];
  overrideCssClassName: Scalars['String']['output'];
  overrideCssDeclaration: Maybe<Scalars['String']['output']>;
  overrideCssFontFamilyName: Scalars['String']['output'];
  position: Maybe<Scalars['Int']['output']>;
  textTransform: TextTransform;
}

export interface Product {
  __typename: 'Product';
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  images: Array<ProductImage>;
  internationalShippingPriceTierOne: Scalars['Float']['output'];
  internationalShippingPriceTierThree: Scalars['Float']['output'];
  internationalShippingPriceTierTwo: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  slug: Scalars['String']['output'];
}

export interface ProductImage {
  __typename: 'ProductImage';
  id: Scalars['Int']['output'];
  url: ProductImageUrl;
}

export interface ProductImageUrl {
  __typename: 'ProductImageUrl';
  large: Scalars['String']['output'];
  medium: Scalars['String']['output'];
  small: Scalars['String']['output'];
}

export interface FontTrialArchive {
  __typename: 'FontTrialArchive';
  description: Maybe<Scalars['String']['output']>;
  family: FontFamily;
  fontsCount: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  position: Scalars['Int']['output'];
}

export interface FontTypeTester {
  __typename: 'FontTypeTester';
  content: Scalars['String']['output'];
  faceId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  lineHeight: Scalars['Float']['output'];
  position: Maybe<Scalars['Int']['output']>;
  size: Scalars['Float']['output'];
  textAlign: TextAlign;
}

export enum TextAlign {
  Center = 'CENTER',
  Left = 'LEFT',
  Right = 'RIGHT'
}

export interface FontLicenseOption {
  __typename: 'FontLicenseOption';
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  kind: Scalars['String']['output'];
  multiplier: Scalars['Float']['output'];
  suffix: Scalars['String']['output'];
}

export interface LineItem {
  __typename: 'LineItem';
  id: Scalars['Int']['output'];
  order: Order;
  product: Product;
  quantity: Scalars['Int']['output'];
  total: Scalars['Float']['output'];
}

export interface Payment {
  __typename: 'Payment';
  amount: Maybe<Scalars['Float']['output']>;
  cardBrand: Maybe<Scalars['String']['output']>;
  cardLast4: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
}

export interface OrderTotals {
  __typename: 'OrderTotals';
  adjustmentsTotal: Scalars['Float']['output'];
  shippingTotal: Scalars['Float']['output'];
  subtotal: Scalars['Float']['output'];
  taxTotal: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
}

export interface User {
  __typename: 'User';
  email: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  invoices: Array<Invoice>;
  name: Maybe<Scalars['String']['output']>;
  orders: Array<Order>;
  organization: Maybe<Scalars['String']['output']>;
  organizationRole: Maybe<Scalars['String']['output']>;
}

export interface Invoice {
  __typename: 'Invoice';
  allowCreditCard: Maybe<Scalars['Boolean']['output']>;
  amount: Scalars['Float']['output'];
  attachmentUrl: Maybe<Scalars['String']['output']>;
  date: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  invoiceeBusiness: Maybe<Scalars['String']['output']>;
  invoiceeEmail: Scalars['String']['output'];
  invoiceeName: Scalars['String']['output'];
  number: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  state: Scalars['String']['output'];
}

export interface Event {
  __typename: 'Event';
  description: Maybe<Scalars['String']['output']>;
  endDate: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  location: Maybe<Scalars['String']['output']>;
  metadata: Maybe<Scalars['String']['output']>;
  otherRecapUrl: Maybe<Scalars['String']['output']>;
  startDate: Scalars['String']['output'];
  title: Scalars['String']['output'];
  url: Maybe<Scalars['String']['output']>;
  videoRecapUrl: Maybe<Scalars['String']['output']>;
}

export interface FaqGroup {
  __typename: 'FaqGroup';
  faqs: Array<Faq>;
  id: Scalars['Int']['output'];
  position: Scalars['Int']['output'];
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
}

export interface Faq {
  __typename: 'Faq';
  content: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  position: Scalars['Int']['output'];
  question: Scalars['String']['output'];
  slug: Scalars['String']['output'];
}

export interface HomePageFeature {
  __typename: 'HomePageFeature';
  caption: Scalars['String']['output'];
  captionUrl: Maybe<Scalars['String']['output']>;
  category: Maybe<Scalars['String']['output']>;
  categoryUrl: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  image1Url: HomePageFeatureImageUrl;
  image2Url: HomePageFeatureImageUrl;
  image3Url: HomePageFeatureImageUrl;
}

export interface HomePageFeatureImageUrl {
  __typename: 'HomePageFeatureImageUrl';
  large: Scalars['String']['output'];
  medium: Scalars['String']['output'];
  small: Scalars['String']['output'];
}

export interface Page {
  __typename: 'Page';
  content: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  slug: Scalars['String']['output'];
  title: Maybe<Scalars['String']['output']>;
}

export interface Project {
  __typename: 'Project';
  captions: Array<ProjectCaption>;
  id: Scalars['Int']['output'];
  images: Array<ProjectImage>;
  imageUrl: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
}

export interface ProjectCaption {
  __typename: 'ProjectCaption';
  content: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  label: Scalars['String']['output'];
}

export interface ProjectImage {
  __typename: 'ProjectImage';
  altText: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  imageUrl: Maybe<Scalars['String']['output']>;
}

export interface FontUsageExample {
  __typename: 'FontUsageExample';
  credits: Array<FontUsageExampleCredit>;
  families: Array<FontFamily>;
  id: Scalars['Int']['output'];
  images: Array<FontUsageExampleImage>;
  preview: FontUsageExamplePreviewStyles;
  projects: Array<Project>;
  published: Maybe<Scalars['Boolean']['output']>;
  slug: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
}


export interface FontUsageExampleImagesArgs {
  first?: InputMaybe<Scalars['Int']['input']>;
}

export interface FontUsageExampleCredit {
  __typename: 'FontUsageExampleCredit';
  credit: Scalars['String']['output'];
  creditUrl: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
}

export interface FontUsageExampleImage {
  __typename: 'FontUsageExampleImage';
  id: Scalars['Int']['output'];
  styles: FontUsageExampleImageStyles;
}

export interface FontUsageExampleImageStyles {
  __typename: 'FontUsageExampleImageStyles';
  w1600: Maybe<Scalars['String']['output']>;
  w200: Maybe<Scalars['String']['output']>;
  w3200: Maybe<Scalars['String']['output']>;
  w400: Maybe<Scalars['String']['output']>;
  w800: Maybe<Scalars['String']['output']>;
}

export interface FontUsageExamplePreviewStyles {
  __typename: 'FontUsageExamplePreviewStyles';
  w1600: Maybe<Scalars['String']['output']>;
  w200: Maybe<Scalars['String']['output']>;
  w3200: Maybe<Scalars['String']['output']>;
  w400: Maybe<Scalars['String']['output']>;
  w800: Maybe<Scalars['String']['output']>;
}

export interface Mutation {
  __typename: 'Mutation';
  applyUpgradeToCurrentOrder: Maybe<ApplyUpgradeToCurrentOrderPayload>;
  completeCurrentOrder: Maybe<CompleteCurrentOrderPayload>;
  completeInvoice: Maybe<CompleteInvoicePayload>;
  createFontLicense: Maybe<CreateFontLicensePayload>;
  createFontTrial: Maybe<CreateFontTrialPayload>;
  createLineItem: Maybe<CreateLineItemPayload>;
  createSession: Maybe<CreateSessionPayload>;
  createUser: Maybe<CreateUserPayload>;
  destroyFontLicense: Maybe<DestroyFontLicensePayload>;
  destroySession: Maybe<DestroySessionPayload>;
  generateFontLicenseArchive: Maybe<GenerateFontLicenseArchivePayload>;
  resetPassword: Maybe<ResetPasswordPayload>;
  subscribeToNewsletter: Maybe<SubscribeToNewsletterPayload>;
  transitionCurrentOrder: Maybe<TransitionCurrentOrderPayload>;
  updateCurrentUser: Maybe<UpdateCurrentUserPayload>;
  updateFontLicense: Maybe<UpdateFontLicensePayload>;
  updateLineItem: Maybe<UpdateLineItemPayload>;
  updatePassword: Maybe<UpdatePasswordPayload>;
}


export interface MutationApplyUpgradeToCurrentOrderArgs {
  input: ApplyUpgradeToCurrentOrderInput;
}


export interface MutationCompleteCurrentOrderArgs {
  input: CompleteCurrentOrderInput;
}


export interface MutationCompleteInvoiceArgs {
  input: CompleteInvoiceInput;
}


export interface MutationCreateFontLicenseArgs {
  input: CreateFontLicenseInput;
}


export interface MutationCreateFontTrialArgs {
  input: CreateFontTrialInput;
}


export interface MutationCreateLineItemArgs {
  input: CreateLineItemInput;
}


export interface MutationCreateSessionArgs {
  input: CreateSessionInput;
}


export interface MutationCreateUserArgs {
  input: CreateUserInput;
}


export interface MutationDestroyFontLicenseArgs {
  input: DestroyFontLicenseInput;
}


export interface MutationDestroySessionArgs {
  input: DestroySessionInput;
}


export interface MutationGenerateFontLicenseArchiveArgs {
  input: GenerateFontLicenseArchiveInput;
}


export interface MutationResetPasswordArgs {
  input: ResetPasswordInput;
}


export interface MutationSubscribeToNewsletterArgs {
  input: SubscribeToNewsletterInput;
}


export interface MutationTransitionCurrentOrderArgs {
  input: TransitionCurrentOrderInput;
}


export interface MutationUpdateCurrentUserArgs {
  input: UpdateCurrentUserInput;
}


export interface MutationUpdateFontLicenseArgs {
  input: UpdateFontLicenseInput;
}


export interface MutationUpdateLineItemArgs {
  input: UpdateLineItemInput;
}


export interface MutationUpdatePasswordArgs {
  input: UpdatePasswordInput;
}

/** Autogenerated input type of ApplyUpgradeToCurrentOrder */
export interface ApplyUpgradeToCurrentOrderInput {
  billingCountryCode?: InputMaybe<Scalars['String']['input']>;
  billingPostalCode?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  shippingCountryCode?: InputMaybe<Scalars['String']['input']>;
  shippingPostalCode?: InputMaybe<Scalars['String']['input']>;
  upgradeCode: Scalars['String']['input'];
}

/** Autogenerated return type of ApplyUpgradeToCurrentOrder */
export interface ApplyUpgradeToCurrentOrderPayload {
  __typename: 'ApplyUpgradeToCurrentOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Array<Scalars['String']['output']>>;
  order: Maybe<Order>;
  orderTotals: Maybe<OrderTotals>;
}

/** Autogenerated input type of CompleteCurrentOrder */
export interface CompleteCurrentOrderInput {
  billingAddress: AddressAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  licenseeAddress?: InputMaybe<AddressAttributes>;
  mobileLicenseDetails?: InputMaybe<Scalars['String']['input']>;
  shippingAddress?: InputMaybe<AddressAttributes>;
  stripeSourceId: Scalars['String']['input'];
  vatNumber?: InputMaybe<Scalars['String']['input']>;
  webLicenseDetails?: InputMaybe<Scalars['String']['input']>;
}

export interface AddressAttributes {
  address1: Scalars['String']['input'];
  address2?: InputMaybe<Scalars['String']['input']>;
  country: Scalars['String']['input'];
  locality: Scalars['String']['input'];
  name: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  region: Scalars['String']['input'];
}

/** Autogenerated return type of CompleteCurrentOrder */
export interface CompleteCurrentOrderPayload {
  __typename: 'CompleteCurrentOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Array<Scalars['String']['output']>>;
  order: Maybe<Order>;
}

/** Autogenerated input type of CompleteInvoice */
export interface CompleteInvoiceInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  stripeSourceId?: InputMaybe<Scalars['String']['input']>;
}

/** Autogenerated return type of CompleteInvoice */
export interface CompleteInvoicePayload {
  __typename: 'CompleteInvoicePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Array<Scalars['String']['output']>>;
  invoice: Maybe<Invoice>;
}

/** Autogenerated input type of CreateFontLicense */
export interface CreateFontLicenseInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  faceIds: Array<Scalars['Int']['input']>;
  familyId: Scalars['Int']['input'];
  licenseOptionIds: Array<Scalars['Int']['input']>;
}

/** Autogenerated return type of CreateFontLicense */
export interface CreateFontLicensePayload {
  __typename: 'CreateFontLicensePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  fontLicenses: Maybe<Array<FontLicense>>;
  order: Maybe<Order>;
}

/** Autogenerated input type of CreateFontTrial */
export interface CreateFontTrialInput {
  address: AddressAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  fontTrialArchiveIds: Array<Scalars['Int']['input']>;
  subscribeToNewsletter?: InputMaybe<Scalars['Boolean']['input']>;
  usage: FontTrialUsage;
}

export enum FontTrialUsage {
  Personal = 'PERSONAL',
  Professional = 'PROFESSIONAL'
}

/** Autogenerated return type of CreateFontTrial */
export interface CreateFontTrialPayload {
  __typename: 'CreateFontTrialPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  error: Maybe<Scalars['String']['output']>;
  fontTrial: Maybe<FontTrial>;
}

export interface FontTrial {
  __typename: 'FontTrial';
  address: Address;
  archives: Array<FontTrialArchive>;
  companyName: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  usage: FontTrialUsage;
}

/** Autogenerated input type of CreateLineItem */
export interface CreateLineItemInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  productId: Scalars['Int']['input'];
  quantity?: InputMaybe<Scalars['Int']['input']>;
}

/** Autogenerated return type of CreateLineItem */
export interface CreateLineItemPayload {
  __typename: 'CreateLineItemPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Array<Scalars['String']['output']>>;
  lineItem: Maybe<LineItem>;
  order: Maybe<Order>;
}

/** Autogenerated input type of CreateSession */
export interface CreateSessionInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
}

/** Autogenerated return type of CreateSession */
export interface CreateSessionPayload {
  __typename: 'CreateSessionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Array<Scalars['String']['output']>>;
  user: Maybe<User>;
}

/** Autogenerated input type of CreateUser */
export interface CreateUserInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
  subscribeToNewsletter?: InputMaybe<Scalars['Boolean']['input']>;
}

/** Autogenerated return type of CreateUser */
export interface CreateUserPayload {
  __typename: 'CreateUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Array<Scalars['String']['output']>>;
  user: Maybe<User>;
}

/** Autogenerated input type of DestroyFontLicense */
export interface DestroyFontLicenseInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  licenseId: Scalars['Int']['input'];
}

/** Autogenerated return type of DestroyFontLicense */
export interface DestroyFontLicensePayload {
  __typename: 'DestroyFontLicensePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  error: Maybe<Scalars['String']['output']>;
  order: Maybe<Order>;
}

/** Autogenerated input type of DestroySession */
export interface DestroySessionInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
}

/** Autogenerated return type of DestroySession */
export interface DestroySessionPayload {
  __typename: 'DestroySessionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
}

/** Autogenerated input type of GenerateFontLicenseArchive */
export interface GenerateFontLicenseArchiveInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
}

/** Autogenerated return type of GenerateFontLicenseArchive */
export interface GenerateFontLicenseArchivePayload {
  __typename: 'GenerateFontLicenseArchivePayload';
  archive: Maybe<FontLicenseArchive>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
}

/** Autogenerated input type of ResetPassword */
export interface ResetPasswordInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
}

/** Autogenerated return type of ResetPassword */
export interface ResetPasswordPayload {
  __typename: 'ResetPasswordPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
}

/** Autogenerated input type of SubscribeToNewsletter */
export interface SubscribeToNewsletterInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
}

/** Autogenerated return type of SubscribeToNewsletter */
export interface SubscribeToNewsletterPayload {
  __typename: 'SubscribeToNewsletterPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
}

/** Autogenerated input type of TransitionCurrentOrder */
export interface TransitionCurrentOrderInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nextStep: Scalars['String']['input'];
}

/** Autogenerated return type of TransitionCurrentOrder */
export interface TransitionCurrentOrderPayload {
  __typename: 'TransitionCurrentOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Array<Scalars['String']['output']>>;
  order: Maybe<Order>;
}

/** Autogenerated input type of UpdateCurrentUser */
export interface UpdateCurrentUserInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<Scalars['String']['input']>;
  organizationRole?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
}

/** Autogenerated return type of UpdateCurrentUser */
export interface UpdateCurrentUserPayload {
  __typename: 'UpdateCurrentUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Array<Scalars['String']['output']>>;
  user: Maybe<User>;
}

/** Autogenerated input type of UpdateFontLicense */
export interface UpdateFontLicenseInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  licenseId: Scalars['Int']['input'];
  licenseOptionId: Scalars['Int']['input'];
}

/** Autogenerated return type of UpdateFontLicense */
export interface UpdateFontLicensePayload {
  __typename: 'UpdateFontLicensePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Array<Scalars['String']['output']>>;
  order: Maybe<Order>;
}

/** Autogenerated input type of UpdateLineItem */
export interface UpdateLineItemInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  lineItemId: Scalars['Int']['input'];
  quantity: Scalars['Int']['input'];
}

/** Autogenerated return type of UpdateLineItem */
export interface UpdateLineItemPayload {
  __typename: 'UpdateLineItemPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Array<Scalars['String']['output']>>;
  lineItem: Maybe<LineItem>;
  order: Maybe<Order>;
}

/** Autogenerated input type of UpdatePassword */
export interface UpdatePasswordInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
  resetPasswordToken: Scalars['String']['input'];
}

/** Autogenerated return type of UpdatePassword */
export interface UpdatePasswordPayload {
  __typename: 'UpdatePasswordPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Array<Scalars['String']['output']>>;
  user: Maybe<User>;
}

export type DestroySessionMutationVariables = Exact<{
  input: DestroySessionInput;
}>;


export type DestroySessionMutation = { __typename: 'Mutation', destroySession: { __typename: 'DestroySessionPayload', clientMutationId: string | null } | null };

export type UpdateCurrentUserMutationVariables = Exact<{
  input: UpdateCurrentUserInput;
}>;


export type UpdateCurrentUserMutation = { __typename: 'Mutation', updateCurrentUser: { __typename: 'UpdateCurrentUserPayload', errors: Array<string> | null, user: { __typename: 'User', id: number, email: string, name: string | null, organization: string | null, organizationRole: string | null } | null } | null };

export type AccountUserQueryVariables = Exact<{ [key: string]: never; }>;


export type AccountUserQuery = { __typename: 'Query', currentUser: { __typename: 'User', id: number, email: string, name: string | null, organization: string | null, organizationRole: string | null, orders: Array<{ __typename: 'Order', id: number, number: string, state: string, completedAt: string | null, fontLicenses: Array<{ __typename: 'FontLicense', id: number, version: number | null, option: { __typename: 'FontLicenseOption', id: number, kind: string, description: string, suffix: string }, family: { __typename: 'FontFamily', id: number, name: string } }>, lineItems: Array<{ __typename: 'LineItem', id: number, quantity: number, total: number, product: { __typename: 'Product', id: number, name: string } }> }>, invoices: Array<{ __typename: 'Invoice', id: number, number: string, date: string, slug: string }> } | null };

export type ArticlePagesQueryVariables = Exact<{
  page: Scalars['Int']['input'];
  tag?: InputMaybe<Scalars['String']['input']>;
}>;


export type ArticlePagesQuery = { __typename: 'Query', articlePages: { __typename: 'ArticlePages', count: number, articles: Array<{ __typename: 'Article', id: number, title: string, slug: string, publishedAt: string | null, excerpt: string | null, tags: Array<string>, bannerImageUrl: string | null, longForm: boolean, author: string | null }> } };

export type ArticleQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;


export type ArticleQuery = { __typename: 'Query', article: { __typename: 'Article', id: number, title: string, slug: string, publishedAt: string | null, excerpt: string | null, tags: Array<string>, bannerImageUrl: string | null, content: string, longForm: boolean, author: string | null } | null };

export type ArticleTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type ArticleTagsQuery = { __typename: 'Query', articleTags: Array<{ __typename: 'ArticleTag', id: number, name: string, articlesCount: number }> };

export type DestroyFontLicenseMutationVariables = Exact<{
  input: DestroyFontLicenseInput;
}>;


export type DestroyFontLicenseMutation = { __typename: 'Mutation', destroyFontLicense: { __typename: 'DestroyFontLicensePayload', order: { __typename: 'Order', id: number, subtotal: number } | null } | null };

export type UpdateFontLicenseMutationVariables = Exact<{
  input: UpdateFontLicenseInput;
}>;


export type UpdateFontLicenseMutation = { __typename: 'Mutation', updateFontLicense: { __typename: 'UpdateFontLicensePayload', order: { __typename: 'Order', id: number, subtotal: number, total: number, fontLicenses: Array<{ __typename: 'FontLicense', id: number, total: number, option: { __typename: 'FontLicenseOption', id: number, kind: string }, family: { __typename: 'FontFamily', id: number, name: string }, faces: Array<{ __typename: 'FontFace', id: number, description: string }> }> } | null } | null };

export type UpdateLineItemMutationVariables = Exact<{
  input: UpdateLineItemInput;
}>;


export type UpdateLineItemMutation = { __typename: 'Mutation', updateLineItem: { __typename: 'UpdateLineItemPayload', errors: Array<string> | null, order: { __typename: 'Order', id: number, subtotal: number, lineItems: Array<{ __typename: 'LineItem', id: number, quantity: number, total: number, product: { __typename: 'Product', id: number, name: string, images: Array<{ __typename: 'ProductImage', id: number, url: { __typename: 'ProductImageUrl', small: string } }> } }> } | null } | null };

export type LicenseOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type LicenseOptionsQuery = { __typename: 'Query', licenseOptions: Array<{ __typename: 'FontLicenseOption', id: number, kind: string, multiplier: number, description: string, suffix: string }> };

export type CurrentOrderTotalsQueryVariables = Exact<{
  billingPostalCode: Scalars['String']['input'];
  billingCountryCode: Scalars['String']['input'];
  shippingPostalCode?: InputMaybe<Scalars['String']['input']>;
  shippingCountryCode?: InputMaybe<Scalars['String']['input']>;
}>;


export type CurrentOrderTotalsQuery = { __typename: 'Query', currentOrderTotals: { __typename: 'OrderTotals', subtotal: number, taxTotal: number, adjustmentsTotal: number, shippingTotal: number, total: number } | null };

export type LicensePagesQueryVariables = Exact<{ [key: string]: never; }>;


export type LicensePagesQuery = { __typename: 'Query', desktop: { __typename: 'Page', content: string | null } | null, web: { __typename: 'Page', content: string | null } | null, mobile: { __typename: 'Page', content: string | null } | null };

export type TransitionCurrentOrderMutationVariables = Exact<{
  input: TransitionCurrentOrderInput;
}>;


export type TransitionCurrentOrderMutation = { __typename: 'Mutation', transitionCurrentOrder: { __typename: 'TransitionCurrentOrderPayload', order: { __typename: 'Order', id: number, state: string } | null } | null };

export type CompleteCurrentOrderMutationVariables = Exact<{
  input: CompleteCurrentOrderInput;
}>;


export type CompleteCurrentOrderMutation = { __typename: 'Mutation', completeCurrentOrder: { __typename: 'CompleteCurrentOrderPayload', errors: Array<string> | null, order: { __typename: 'Order', id: number, number: string, state: string } | null } | null };

export type ApplyUpgradeToCurrentOrderMutationVariables = Exact<{
  input: ApplyUpgradeToCurrentOrderInput;
}>;


export type ApplyUpgradeToCurrentOrderMutation = { __typename: 'Mutation', applyUpgradeToCurrentOrder: { __typename: 'ApplyUpgradeToCurrentOrderPayload', errors: Array<string> | null, order: { __typename: 'Order', id: number, upgradeDescription: string | null } | null, orderTotals: { __typename: 'OrderTotals', subtotal: number, taxTotal: number, adjustmentsTotal: number, shippingTotal: number, total: number } | null } | null };

export type PageQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;


export type PageQuery = { __typename: 'Query', page: { __typename: 'Page', id: number, title: string | null, content: string | null } | null };

export type ProjectsQueryVariables = Exact<{ [key: string]: never; }>;


export type ProjectsQuery = { __typename: 'Query', projects: Array<{ __typename: 'Project', id: number, name: string, slug: string, imageUrl: string | null, images: Array<{ __typename: 'ProjectImage', id: number, altText: string | null, imageUrl: string | null }>, captions: Array<{ __typename: 'ProjectCaption', id: number, label: string, content: string }> }> };

export type EventsQueryVariables = Exact<{ [key: string]: never; }>;


export type EventsQuery = { __typename: 'Query', events: Array<{ __typename: 'Event', id: number, title: string, startDate: string, endDate: string | null, location: string | null, metadata: string | null, description: string | null, url: string | null, videoRecapUrl: string | null, otherRecapUrl: string | null }> };

export type CreateFontLicenseMutationVariables = Exact<{
  input: CreateFontLicenseInput;
}>;


export type CreateFontLicenseMutation = { __typename: 'Mutation', createFontLicense: { __typename: 'CreateFontLicensePayload', order: { __typename: 'Order', id: number, state: string, upgradeDescription: string | null, subtotal: number, total: number, fontLicenses: Array<{ __typename: 'FontLicense', id: number, total: number, option: { __typename: 'FontLicenseOption', id: number, kind: string }, family: { __typename: 'FontFamily', id: number, name: string }, faces: Array<{ __typename: 'FontFace', id: number, description: string }> }>, lineItems: Array<{ __typename: 'LineItem', id: number, quantity: number, total: number, product: { __typename: 'Product', id: number, name: string, images: Array<{ __typename: 'ProductImage', id: number, url: { __typename: 'ProductImageUrl', small: string } }> } }> } | null } | null };

export type PreviewOverrideDeclarationsQueryVariables = Exact<{
  familySlug: Scalars['String']['input'];
}>;


export type PreviewOverrideDeclarationsQuery = { __typename: 'Query', family: { __typename: 'FontFamily', previews: Array<{ __typename: 'FontPreview', id: number, overrideCssDeclaration: string | null }> } | null };

export type FontFamiliesQueryVariables = Exact<{ [key: string]: never; }>;


export type FontFamiliesQuery = { __typename: 'Query', families: Array<{ __typename: 'FontFamily', id: number, slug: string, name: string, position: number | null, availableAt: string | null, status: string, foregroundColor: string, backgroundColor: string, previews: Array<{ __typename: 'FontPreview', id: number, position: number | null, textTransform: TextTransform, letterSpacing: number, fontSizeScale: number, hasOverrideFont: boolean, overrideCssClassName: string, face: { __typename: 'FontFace', id: number } }>, faces: Array<{ __typename: 'FontFace', id: number, description: string, variant: string, size: string, width: string, weight: string, style: string, displayOrder: number | null, cssClassName: string }> }> };

export type CompleteInvoiceMutationVariables = Exact<{
  input: CompleteInvoiceInput;
}>;


export type CompleteInvoiceMutation = { __typename: 'Mutation', completeInvoice: { __typename: 'CompleteInvoicePayload', errors: Array<string> | null, invoice: { __typename: 'Invoice', id: number, slug: string, state: string, date: string, amount: number, number: string, invoiceeName: string, invoiceeEmail: string, invoiceeBusiness: string | null, description: string, allowCreditCard: boolean | null, attachmentUrl: string | null } | null } | null };

export type InvoiceQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;


export type InvoiceQuery = { __typename: 'Query', invoice: { __typename: 'Invoice', id: number, slug: string, state: string, date: string, amount: number, number: string, invoiceeName: string, invoiceeEmail: string, invoiceeBusiness: string | null, description: string, allowCreditCard: boolean | null, attachmentUrl: string | null } | null };

export type SubscribeToNewsletterMutationVariables = Exact<{
  input: SubscribeToNewsletterInput;
}>;


export type SubscribeToNewsletterMutation = { __typename: 'Mutation', subscribeToNewsletter: { __typename: 'SubscribeToNewsletterPayload', clientMutationId: string | null } | null };

export type LicenseQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type LicenseQuery = { __typename: 'Query', license: { __typename: 'FontLicense', id: number, adobeFontsToken: string | null } | null };

export type FontLicenseQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type FontLicenseQuery = { __typename: 'Query', fontLicense: { __typename: 'FontLicense', id: number, archive: { __typename: 'FontLicenseArchive', status: string, expiringUrl: string | null } | null } | null };

export type GenerateFontLicenseArchiveMutationVariables = Exact<{
  input: GenerateFontLicenseArchiveInput;
}>;


export type GenerateFontLicenseArchiveMutation = { __typename: 'Mutation', generateFontLicenseArchive: { __typename: 'GenerateFontLicenseArchivePayload', archive: { __typename: 'FontLicenseArchive', status: string, expiringUrl: string | null } | null } | null };

export type OrderQueryVariables = Exact<{
  number: Scalars['String']['input'];
}>;


export type OrderQuery = { __typename: 'Query', order: { __typename: 'Order', id: number, number: string, subtotal: number, taxTotal: number, adjustmentsTotal: number, shippingTotal: number, total: number, webLicenseDetails: string | null, mobileLicenseDetails: string | null, completedAt: string | null, canceledAt: string | null, payment: { __typename: 'Payment', id: number, amount: number | null, cardBrand: string | null, cardLast4: string | null } | null, billingAddress: { __typename: 'Address', name: string, address1: string, address2: string | null, locality: string, region: string, postalCode: string, country: string } | null, licenseeAddress: { __typename: 'Address', name: string, address1: string, address2: string | null, locality: string, region: string, postalCode: string, country: string } | null, shippingAddress: { __typename: 'Address', name: string, address1: string, address2: string | null, locality: string, region: string, postalCode: string, country: string } | null, fontLicenses: Array<{ __typename: 'FontLicense', id: number, version: number | null, total: number, option: { __typename: 'FontLicenseOption', id: number, kind: string, description: string, suffix: string }, family: { __typename: 'FontFamily', id: number, name: string }, faces: Array<{ __typename: 'FontFace', id: number, description: string }> }>, lineItems: Array<{ __typename: 'LineItem', id: number, quantity: number, total: number, product: { __typename: 'Product', id: number, name: string } }> } | null };

export type FaqQueryVariables = Exact<{ [key: string]: never; }>;


export type FaqQuery = { __typename: 'Query', faqGroups: Array<{ __typename: 'FaqGroup', id: number, slug: string, title: string, position: number, faqs: Array<{ __typename: 'Faq', id: number, slug: string, question: string, content: string | null, position: number }> }> };

export type HomePageQueryVariables = Exact<{ [key: string]: never; }>;


export type HomePageQuery = { __typename: 'Query', homePageFeatures: Array<{ __typename: 'HomePageFeature', category: string | null, categoryUrl: string | null, caption: string, captionUrl: string | null, image1Url: { __typename: 'HomePageFeatureImageUrl', small: string, medium: string, large: string }, image2Url: { __typename: 'HomePageFeatureImageUrl', small: string, medium: string, large: string }, image3Url: { __typename: 'HomePageFeatureImageUrl', small: string, medium: string, large: string } }>, families: Array<{ __typename: 'FontFamily', id: number, slug: string, name: string, position: number | null, availableAt: string | null, status: string, onHomePage: boolean, foregroundColor: string, backgroundColor: string, previews: Array<{ __typename: 'FontPreview', id: number, position: number | null, textTransform: TextTransform, letterSpacing: number, fontSizeScale: number, hasOverrideFont: boolean, overrideCssClassName: string, face: { __typename: 'FontFace', id: number } }>, faces: Array<{ __typename: 'FontFace', id: number, description: string, variant: string, size: string, width: string, weight: string, style: string, displayOrder: number | null, cssClassName: string }> }>, latestArticle: { __typename: 'Article', id: number, title: string, slug: string, publishedAt: string | null, excerpt: string | null, tags: Array<string>, bannerImageUrl: string | null, longForm: boolean, author: string | null } | null, projects: Array<{ __typename: 'Project', id: number, name: string, slug: string, imageUrl: string | null, captions: Array<{ __typename: 'ProjectCaption', id: number, label: string, content: string }> }>, usageExamples: Array<{ __typename: 'FontUsageExample', id: number, title: string | null, slug: string | null, published: boolean | null, preview: { __typename: 'FontUsageExamplePreviewStyles', w200: string | null, w400: string | null, w800: string | null, w1600: string | null, w3200: string | null } }> };

export type CreateSessionMutationVariables = Exact<{
  input: CreateSessionInput;
}>;


export type CreateSessionMutation = { __typename: 'Mutation', createSession: { __typename: 'CreateSessionPayload', errors: Array<string> | null, user: { __typename: 'User', id: number, email: string, name: string | null, organization: string | null, organizationRole: string | null } | null } | null };

export type ResetPasswordMutationVariables = Exact<{
  input: ResetPasswordInput;
}>;


export type ResetPasswordMutation = { __typename: 'Mutation', resetPassword: { __typename: 'ResetPasswordPayload', clientMutationId: string | null } | null };

export type UpdatePasswordMutationVariables = Exact<{
  input: UpdatePasswordInput;
}>;


export type UpdatePasswordMutation = { __typename: 'Mutation', updatePassword: { __typename: 'UpdatePasswordPayload', user: { __typename: 'User', id: number, email: string, name: string | null, organization: string | null, organizationRole: string | null } | null } | null };

export type CreateLineItemMutationVariables = Exact<{
  input: CreateLineItemInput;
}>;


export type CreateLineItemMutation = { __typename: 'Mutation', createLineItem: { __typename: 'CreateLineItemPayload', errors: Array<string> | null, order: { __typename: 'Order', id: number, subtotal: number, lineItems: Array<{ __typename: 'LineItem', id: number, quantity: number, total: number, product: { __typename: 'Product', id: number, name: string, images: Array<{ __typename: 'ProductImage', id: number, url: { __typename: 'ProductImageUrl', small: string } }> } }> } | null } | null };

export type ProductsQueryVariables = Exact<{ [key: string]: never; }>;


export type ProductsQuery = { __typename: 'Query', products: Array<{ __typename: 'Product', id: number, name: string, description: string | null, price: number, internationalShippingPriceTierOne: number, images: Array<{ __typename: 'ProductImage', id: number, url: { __typename: 'ProductImageUrl', small: string, medium: string, large: string } }> }> };

export type FontLicenseAttributes = { __typename: 'FontLicense', id: number, total: number, option: { __typename: 'FontLicenseOption', id: number, kind: string }, family: { __typename: 'FontFamily', id: number, name: string }, faces: Array<{ __typename: 'FontFace', id: number, description: string }> };

export type LineItemAttributes = { __typename: 'LineItem', id: number, quantity: number, total: number, product: { __typename: 'Product', id: number, name: string, images: Array<{ __typename: 'ProductImage', id: number, url: { __typename: 'ProductImageUrl', small: string } }> } };

export type CurrentOrderQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentOrderQuery = { __typename: 'Query', currentOrder: { __typename: 'Order', id: number, state: string, upgradeDescription: string | null, total: number, subtotal: number, fontLicenses: Array<{ __typename: 'FontLicense', id: number, total: number, option: { __typename: 'FontLicenseOption', id: number, kind: string }, family: { __typename: 'FontFamily', id: number, name: string }, faces: Array<{ __typename: 'FontFace', id: number, description: string }> }>, lineItems: Array<{ __typename: 'LineItem', id: number, quantity: number, total: number, product: { __typename: 'Product', id: number, name: string, images: Array<{ __typename: 'ProductImage', id: number, url: { __typename: 'ProductImageUrl', small: string } }> } }> } | null };

export type UserAttributes = { __typename: 'User', id: number, email: string, name: string | null, organization: string | null, organizationRole: string | null };

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = { __typename: 'Query', currentUser: { __typename: 'User', id: number, email: string, name: string | null, organization: string | null, organizationRole: string | null } | null };

export type CountriesQueryVariables = Exact<{ [key: string]: never; }>;


export type CountriesQuery = { __typename: 'Query', countries: Array<{ __typename: 'Country', name: string, alpha2: string }> };

export type FontFaceDeclarationsQueryVariables = Exact<{
  ids: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;


export type FontFaceDeclarationsQuery = { __typename: 'Query', faces: Array<{ __typename: 'FontFace', id: number, cssDeclaration: string | null }> };

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;


export type CreateUserMutation = { __typename: 'Mutation', createUser: { __typename: 'CreateUserPayload', errors: Array<string> | null, user: { __typename: 'User', id: number, email: string, name: string | null, organization: string | null, organizationRole: string | null } | null } | null };

export type TrialArchivesFamiliesQueryVariables = Exact<{ [key: string]: never; }>;


export type TrialArchivesFamiliesQuery = { __typename: 'Query', families: Array<{ __typename: 'FontFamily', id: number, name: string, slug: string, trialArchives: Array<{ __typename: 'FontTrialArchive', id: number, name: string, description: string | null, fontsCount: number, position: number }> }> };

export type CreateFontTrialMutationVariables = Exact<{
  input: CreateFontTrialInput;
}>;


export type CreateFontTrialMutation = { __typename: 'Mutation', createFontTrial: { __typename: 'CreateFontTrialPayload', error: string | null, fontTrial: { __typename: 'FontTrial', id: number } | null } | null };

export type UsageExampleFamiliesQueryVariables = Exact<{ [key: string]: never; }>;


export type UsageExampleFamiliesQuery = { __typename: 'Query', families: Array<{ __typename: 'FontFamily', id: number, slug: string, name: string }> };

export type UsageExampleQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;


export type UsageExampleQuery = { __typename: 'Query', usageExample: { __typename: 'FontUsageExample', id: number, title: string | null, slug: string | null, published: boolean | null, families: Array<{ __typename: 'FontFamily', id: number, name: string, slug: string }>, projects: Array<{ __typename: 'Project', id: number, name: string, slug: string }>, credits: Array<{ __typename: 'FontUsageExampleCredit', title: string, credit: string, creditUrl: string | null }>, images: Array<{ __typename: 'FontUsageExampleImage', id: number, styles: { __typename: 'FontUsageExampleImageStyles', w200: string | null, w400: string | null, w800: string | null, w1600: string | null, w3200: string | null } }> } | null };

export type UsageExamplesQueryVariables = Exact<{ [key: string]: never; }>;


export type UsageExamplesQuery = { __typename: 'Query', usageExamples: Array<{ __typename: 'FontUsageExample', id: number, title: string | null, slug: string | null, published: boolean | null, preview: { __typename: 'FontUsageExamplePreviewStyles', w200: string | null, w400: string | null, w800: string | null, w1600: string | null, w3200: string | null }, families: Array<{ __typename: 'FontFamily', id: number, name: string, slug: string }>, projects: Array<{ __typename: 'Project', id: number, name: string }> }> };

export type FontFamilyAndLicenseOptionsQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;


export type FontFamilyAndLicenseOptionsQuery = { __typename: 'Query', family: { __typename: 'FontFamily', id: number, slug: string, name: string, headingLetterSpacing: number, headingTextTransform: TextTransform, foregroundColor: string, backgroundColor: string, logoBackgroundColor: string | null, description: string | null, designCredit: string | null, supportedLanguages: Array<string> | null, completePrice: number, fontFacePrice: number, discountedFontFacePrice: number, completePackageDescription: string | null, minTesterFontSize: number, maxTesterFontSize: number, featuresDeclaration: string | null, specimenDocumentUrl: string | null, specimenPreviewUrl: string | null, articleImageUrl: string | null, status: string, headingFace: { __typename: 'FontFace', id: number, cssClassName: string } | null, faces: Array<{ __typename: 'FontFace', id: number, variant: string, size: string, weight: string, width: string, style: string, description: string, previewUrl: string | null, retinaPreviewUrl: string | null, cssClassName: string }>, packages: Array<{ __typename: 'FontPackage', id: number, name: string, variant: string, size: string | null, width: string | null, price: number, discountedPrice: number | null, description: string | null }>, features: Array<{ __typename: 'FontFeature', id: number, name: string, characterCodes: string, caption: string | null }>, typeTesters: Array<{ __typename: 'FontTypeTester', id: number, position: number | null, faceId: number, content: string, size: number, lineHeight: number, textAlign: TextAlign }>, article: { __typename: 'Article', id: number, slug: string, title: string, author: string | null } | null } | null, licenseOptions: Array<{ __typename: 'FontLicenseOption', id: number, kind: string, multiplier: number, description: string, suffix: string }> };
