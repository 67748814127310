import './Family.scss';

import React, { useState } from 'react';
import { Link, RouteComponentProps } from '@reach/router';

import * as Core from 'components/core';
import { TrialArchivesFamiliesQuery } from 'client/graphql/types/operations';
import { sortBy } from 'lodash-es';

interface Props extends RouteComponentProps {
  family: TrialArchivesFamiliesQuery['families'][number];
}

const TrialFamily: React.FC<Props> = (props) => {
  const { family } = props;

  const [trialArchiveIds, setTrialArchiveIds] = useState<number[]>([]);

  return (
    <div className="TrialFamily">
      <div className="TrialFamily-info">
        <div className="TrialFamily-info-name">
          <h2>{family.name}</h2>

          <Link
            to={`/families/${family.slug}`}
            className="TrialFamily-info-name-link"
          >
            Explore this Family
          </Link>
        </div>

        <div className="TrialFamily-info-table">
          {sortBy(family.trialArchives, 'position').map((trialArchive) => (
            <div key={trialArchive.id} className="TrialFamily-info-table-row">
              <div className="TrialFamily-info-table-row-width">
                <Core.Checkbox
                  checked={trialArchiveIds.includes(trialArchive.id)}
                  onChange={(event) => {
                    const { checked } = event.currentTarget;

                    setTrialArchiveIds((ids) => {
                      if (checked) {
                        return [...ids, trialArchive.id];
                      } else {
                        return ids.filter((id) => id !== trialArchive.id);
                      }
                    });
                  }}
                >
                  {trialArchive.name}
                </Core.Checkbox>
              </div>

              <div className="TrialFamily-info-table-row-packages">
                {trialArchive.description}
              </div>

              <div className="TrialFamily-info-table-row-fonts">
                {trialArchive.fontsCount} fonts
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="TrialFamily-buyButton">
        <Core.Link
          button
          fullWidth
          disabled={trialArchiveIds.length === 0}
          to={`/trial-fonts/request?trialArchiveIds=${trialArchiveIds.join(
            ',',
          )}`}
        >
          Get {family.name} Trial Fonts
        </Core.Link>
      </div>
    </div>
  );
};

export default TrialFamily;
