import React from 'react';
import { Router } from '@reach/router';
import { ApolloProvider } from 'react-apollo';
import { I18nextProvider } from 'react-i18next';

import client from 'apolloClient';
import StoreProvider from 'modules/store-provider';
import translations from 'translations';
import LoadInitialData from './LoadInitialData';
import * as Core from './core';
import * as Pages from './pages';
import Home from './pages/Home';
import Families from './families';
import Checkout from './checkout';
import Blog from './blog';
import Orders from './orders';
import Registration from './Registration';
import Header from './Header';
import Footer from './Footer';
import CookieNotice from './cookie-notice/CookieNotice';
import Trials from './trials';

const Cart = React.lazy(() => import('./cart'));
const Events = React.lazy(() => import('./events'));
const Account = React.lazy(() => import('./account'));
const Invoice = React.lazy(() => import('./invoice'));
const Passwords = React.lazy(() => import('./passwords'));
const Newsletter = React.lazy(() => import('./newsletter'));
const Custom = React.lazy(() => import('./custom'));
const Products = React.lazy(() => import('./products'));
const UsageExamples = React.lazy(() => import('./usage-examples'));

export default function Application() {
  return (
    <Core.BugsnagErrorBoundary>
      <Core.DocumentTitle>
        <Core.StripeProvider apiKey={window.ENV.stripeApiKey}>
          <ApolloProvider client={client}>
            <StoreProvider>
              <I18nextProvider i18n={translations}>
                <LoadInitialData>
                  <Core.ScrollToTop />

                  <Header />

                  <Core.Notifications />

                  <Core.BugsnagErrorBoundary>
                    <React.Suspense fallback={<Core.Loader />}>
                      <Router primary={false}>
                        <Home path="/" />
                        <Families path="/families/*" />
                        <Blog path="/blog/*" />

                        <Registration path="/registration" />
                        <Pages.Session path="/session" />
                        <Account path="/account" />
                        <Passwords path="/password-reset" />

                        <Cart path="/cart" />
                        <Checkout path="/checkout/*" />
                        <Orders path="/order/:number" />
                        <Invoice path="/invoice/:slug" />

                        <Events path="/events" />
                        <Products path="/goods" />
                        <Custom path="/custom" />
                        <Newsletter path="/newsletter" />
                        <UsageExamples path="/in-use/*" />

                        <Pages.About path="/about" />
                        <Pages.Licensing path="/licensing/*" />
                        <Pages.FrequentlyAskedQuestions path="/faq" />
                        <Pages.PrivacyPolicy path="/privacy" />
                        <Pages.TermsOfUse path="/terms" />
                        <Pages.WorkWithUs path="/work-with-us" />
                        <Pages.Press path="/press" />

                        <Trials path="/trial-fonts/*" />

                        <Core.NotFound default />
                      </Router>
                    </React.Suspense>
                  </Core.BugsnagErrorBoundary>

                  <CookieNotice />

                  <Footer />
                </LoadInitialData>
              </I18nextProvider>
            </StoreProvider>
          </ApolloProvider>
        </Core.StripeProvider>
      </Core.DocumentTitle>
    </Core.BugsnagErrorBoundary>
  );
}
