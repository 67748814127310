import React from 'react';

interface Props {
  textColor?: string;
}

const Logo: React.FC<Props> = (props) => {
  const { textColor = 'transparent' } = props;

  return (
    <svg x="0px" y="0px" viewBox="0 0 95.7 59.1">
      <rect x={0} y={0} height="100%" width="92%" fill={textColor} />

      <path
        d="M0,0h95.7v15h-5.9v6.7h5.9v37.4H0V0z M33.5,47.6H33v-4.3h-2v6.2c0.9,0.4,1.8,0.6,3.1,0.6c2.5,0,5-1.5,5-5
V33.2h1.4v-2.5h-6.4v2.5h1.5v12.1C35.7,47,35,47.6,33.5,47.6z M39.6,20.1v-2.5h-3.9v-6H38v3.4h2.1V9h-8.9v2.5h1.1v14.2h-1.1v2.5h6
v-2.5h-1.5v-5.6H39.6z M46.4,20.2h0.2c1.4,0,1.7,0.4,1.8,1.8l0.4,6.3h4v-2.5h-0.8l-0.3-3.8C51.5,20,51,19.3,49.3,19v-0.1
c1.6-0.8,2.8-2.2,2.8-4.9c0-3.6-1.4-5-4.9-5h-5.3v2.5h1v14.2h-1.2v2.5h4.7V20.2z M46.8,50.2c3.5,0,4.8-2.7,4.8-8.1v-3.5
c0-5.6-1.3-8.2-4.8-8.2c-3.5,0-4.8,2.5-4.8,8.2v3.5C42,47.5,43.3,50.2,46.8,50.2z M48.2,44.3c0,2.4-0.5,3.5-1.4,3.5
c-0.9,0-1.4-1.1-1.4-3.5v-7.8c0-2.5,0.5-3.5,1.4-3.5c0.9,0,1.4,0.9,1.4,3.5V44.3z M46.7,17.8h-0.4v-6.4h0.4c1.4,0,2.1,0.5,2.1,3
C48.8,17.3,48.1,17.8,46.7,17.8z M62.1,42.2h-0.2l-3.3-11.5h-5.3v2.5h1.3v14.2h-1.3V50H58v-2.5h-1.1v-11h0.2L61,50h3.4V33.2h0.9
v-2.5h-4.2v2.5h0.9V42.2z M62.6,19.6V17h-3.9v-5.4h2.4v3.3h2.1V9h-9.4v2.5h1.4v14.2h-0.9v2.5h9v-6.1h-2.1v3.6h-2.5v-6.1H62.6z
M69.7,20.2h0.2c1.4,0,1.7,0.4,1.8,1.8l0.4,6.3h4v-2.5h-0.8l-0.3-3.8c-0.1-1.9-0.6-2.5-2.3-2.9v-0.1c1.6-0.8,2.8-2.2,2.8-4.9
c0-3.6-1.4-5-4.9-5h-5.3v2.5h1v14.2h-1v2.5h4.5V20.2z M75.5,41.3v-2.6h-4v-5.4H74v3.3h2.1v-5.8h-9v2.5H68v14.2h-1.3V50h9.4v-6.1H74
v3.6h-2.5v-6.1H75.5z M70,17.8h-0.4v-6.4H70c1.4,0,2.1,0.5,2.1,3C72.1,17.3,71.4,17.8,70,17.8z M85.9,19.6V17h-3.9v-5.4h2.4v3.3
h2.1V9H77v2.5h1.4v14.2h-0.9v2.5h9v-6.1h-2.1v3.6h-2.5v-6.1H85.9z M83.8,38.8c-2-1.7-2.5-2.4-2.5-3.8c0-1.2,0.7-2,2.1-2h0.9v3.4
h2.1v-5.1c-1.1-0.5-2.1-0.8-3.5-0.8c-3.1,0-4.9,1.6-4.9,4.8c0,2.8,1.1,4.1,3.1,5.9c2,1.7,2.5,2.3,2.5,4c0,1.5-0.9,2.4-2.5,2.4h-1
v-3.8H78v5.5c1.1,0.5,2.2,0.8,3.6,0.8c3.2,0,5.3-1.9,5.3-5.3C86.9,42.1,85.7,40.6,83.8,38.8z"
      />
    </svg>
  );
};

export default Logo;
