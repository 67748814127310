import './Checkbox.scss';

import React from 'react';
import classNames from 'classnames';

type HTMLInputElementProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

interface Props extends HTMLInputElementProps {
  invalid: boolean;
  testID?: string;
  children: React.ReactNode;
}

class Checkbox extends React.PureComponent<Props> {
  static defaultProps = {
    disabled: false,
    invalid: false,
  };

  render() {
    const { invalid, testID, children, ...inputProps } = this.props;

    return (
      <label
        className={classNames('Checkbox', {
          'is-checked': inputProps.checked,
          'is-disabled': inputProps.disabled,
          'is-invalid': invalid,
        })}
      >
        <input
          {...inputProps}
          className="Checkbox-input"
          type="checkbox"
          data-test-id={testID}
        />

        <span className="Checkbox-label">{children}</span>
      </label>
    );
  }
}

export default Checkbox;
