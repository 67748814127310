import './TeamMember.scss';

import React from 'react';

interface Props {
  imageSrc?: string;
  name: string;
  title: string;
  children: React.ReactNode;
}

class TeamMember extends React.PureComponent<Props> {
  render() {
    const { imageSrc, name, title, children } = this.props;
    return (
      <div className="TeamMember">
        <div className="TeamMember-photo">
          {imageSrc && (
            <img className="TeamMember-photo-image" src={imageSrc} />
          )}
        </div>
        <div className="TeamMember-text">
          <div className="TeamMember-text-title">
            <h2 className="heading-2 TeamMember-text-title-name">
              {name}
              {' — '}
              <span className="TeamMember-text-title-role">{title}</span>
            </h2>
          </div>
          <div>{children}</div>
        </div>
      </div>
    );
  }
}

export default TeamMember;
